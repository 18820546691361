import React, { useEffect, useState } from "react"
import { useTheme } from "../../context/ThemeContext"

// Utilities
import { slideUp, slideToggle } from "../../utilities/slide"
import lazyLoad from "../../utilities/handleLazyLoad"

// Components
import { Link } from "gatsby"
const Country = lazyLoad(() => import("./Country"))

const Header = ({ modifierClass }) => {
  const { shipping, cart } = useTheme()

  /*==============================
   HANDLE MENU ON SCROLL
  ===============================*/
  // Set jump menu visibility
  const [isJumpMenu, setJumpMenu] = useState(false)

  // Set/unset header sticky state
  const handleScroll = () => {
    const offset = window.scrollY
    const header = document.querySelector(".header")

    setJumpMenu(document.querySelectorAll(".responsive-menu-btn").length)

    if (header) {
      const headerHeight = header.clientHeight

      const preheader = document.querySelector(".preheader")
      const preheaderHeight = preheader ? preheader.clientHeight : 0

      const discount = document.querySelector(".discount-preheader")
      const discountHeight = discount ? discount.clientHeight : 0

      if (offset >= preheaderHeight + discountHeight) {
        if (preheader || discount) {
          document.body.style.paddingTop = headerHeight + "px"
          document.body.classList.add("sticky-menu")
        }
      } else {
        if (preheader || discount) {
          document.body.style.paddingTop = 0
          document.body.classList.remove("sticky-menu")
        }
      }
    }
  }

  // Set event listeners for scroll and resize for the header sticky state
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", handleScroll)
  })

  /*==============================
   HANDLE MENU DROPDOWN
  ===============================*/

  // Handle menu dropdown open/close
  const handleMenuDropdown = e => {
    e.preventDefault()

    const menuItemDropdown = document.querySelectorAll(".menu-item--has-dropdown")
    let menuItemLi = e.target.parentNode

    if (window.innerWidth <= 991) {
      ;[].filter.call(menuItemDropdown, elem => {
        if (elem !== menuItemLi) {
          slideUp(elem.querySelector(".menu-dropdown-toggeable"), elem)
          elem.classList.remove("open")
        } else {
          slideToggle(menuItemLi.querySelector(".menu-dropdown-toggeable"), elem)
          menuItemLi.classList.toggle("open")
        }
      })
    }
  }

  // Handle menu nested dropdown open/close
  const handleMenuNestedDropdown = e => {
    e.preventDefault()
    const menuItemDropdown = document.querySelectorAll(".menu-dropdown__submenu")
    let menuItemLi = e.target.parentNode

    if (window.innerWidth <= 991) {
      ;[].filter.call(menuItemDropdown, elem => {
        if (elem !== menuItemLi) {
          slideUp(elem.querySelector(".menu-submenu-dropdown"), elem)
          elem.classList.remove("open")
        } else {
          slideToggle(menuItemLi.querySelector(".menu-submenu-dropdown"), elem)
          menuItemLi.classList.toggle("open")
        }
      })
    }
  }

  /*==============================
   HANDLE BODY OVERLAY
  ===============================*/
  const [menuOverlay, setMenuOverlay] = useState(false)

  // Remove body overlay if !menuOverlay
  useEffect(() => {
    if (!menuOverlay) {
      document.body.classList.remove("menu-open")
    }
  }, [menuOverlay])

  // Set body overlay
  const handleMenu = () => {
    document.body.classList.toggle("menu-open")
    document.body.classList.contains("menu-open") ? setMenuOverlay(true) : setMenuOverlay(false)
  }

  // Close sidebar and remove body overlay
  const closeSidebar = () => {
    if (window.innerWidth <= 991) {
      document.body.classList.remove("menu-open")
      setMenuOverlay(false)
    }
  }

  return (
    <>
      {menuOverlay && (
        <span
          aria-label="Page Overlay."
          role="button"
          tabIndex="0"
          onClick={handleMenu}
          onKeyDown={handleMenu}
          className="overlay"
        />
      )}
      <header className={`header ${modifierClass ? modifierClass : ""}`}>
        <div className="container d-flex justify-content-between align-items-center">
          <div className="header--left">
            <button className="menu-toggle d-lg-none" onClick={handleMenu} aria-label="Toggle Menu.">
              <span />
              <span />
              <span />
            </button>
            <Link onClick={closeSidebar} to="/" className="logo">
              <img src="/images/logo.svg" className="shadow-0" alt="." width="216" height="49" />
            </Link>
          </div>
          <nav className="menu-nav">
            <div className="d-flex d-lg-none">
              <Country />
            </div>

            <ul className={`menu ${isJumpMenu ? "menu--padding" : ""}`}>
              <li className="menu-item menu-item--has-dropdown">
                <div
                  aria-label="Open Programs."
                  role="button"
                  tabIndex={-1}
                  onClick={handleMenuDropdown}
                  onKeyDown={handleMenuDropdown}
                >
                  <Link
                    className="menu-item__btn"
                    onClick={closeSidebar}
                    to="/category/programs/"
                    activeClassName="active"
                  >
                    programs
                  </Link>
                </div>
                <div className="menu-dropdown-toggeable">
                  <ul className="menu-dropdown pr--0">
                    <li className="menu-item menu-dropdown__submenu">
                      <div
                        aria-label="Open Workout Plans."
                        role="button"
                        tabIndex={-1}
                        onClick={handleMenuNestedDropdown}
                        onKeyDown={handleMenuNestedDropdown}
                      >
                        <Link
                          className="menu-item__btn"
                          onClick={closeSidebar}
                          to="/category/workout-plans/"
                          activeClassName="active"
                        >
                          Workout plans
                        </Link>
                      </div>
                      <ul className="menu-submenu-dropdown">
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/perimenofit/" activeClassName="active">
                            PerimenoFit <span className="badge-new" />
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/90-day-challenge/" activeClassName="active">
                            90 day challenge
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/home-workout-domination/" activeClassName="active">
                            Home Workout Domination
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            onClick={closeSidebar}
                            to="/products/home-workout-domination-2/"
                            activeClassName="active"
                          >
                            Home Workout Domination 2
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            onClick={closeSidebar}
                            to="/products/30-day-booty-abs-challenge/"
                            activeClassName="active"
                          >
                            Booty and Abs Challenge
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/lioness/" activeClassName="active">
                            Lioness Strength Training
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-dropdown__submenu">
                      <div
                        aria-label="Open Meal Plans."
                        role="button"
                        tabIndex={-1}
                        onClick={handleMenuNestedDropdown}
                        onKeyDown={handleMenuNestedDropdown}
                      >
                        <Link
                          className="menu-item__btn"
                          onClick={closeSidebar}
                          to="/category/meal-plans/"
                          activeClassName="active"
                        >
                          Meal plans
                        </Link>
                      </div>
                      <ul className="menu-submenu-dropdown">
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/my-dinner-plan/" activeClassName="active">
                            My Dinner plan <span className="badge-new" />
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/body-fuel-system/" activeClassName="active">
                            Body Fuel System
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/30-day-meal-plan/" activeClassName="active">
                            30 day meal plan
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={closeSidebar} to="/products/7-day-meal-plan/" activeClassName="active">
                            7 day meal plan
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a
                        href="https://shop.thebettyrocker.com/rock-your-life/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rock Your Life
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              {shipping && (
                <li className="menu-item menu-item--has-dropdown">
                  <div
                    aria-label="Open Supplements."
                    role="button"
                    tabIndex={-1}
                    onClick={handleMenuDropdown}
                    onKeyDown={handleMenuDropdown}
                  >
                    <Link
                      className="menu-item__btn"
                      onClick={closeSidebar}
                      to="/category/supplements/"
                      activeClassName="active"
                    >
                      supplements
                    </Link>
                  </div>
                  <div className="menu-dropdown-toggeable">
                    <ul className="menu-dropdown">
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/category/bundles/" activeClassName="active">
                          bundles <span className="badge-new" />
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/vanilla-protein/" activeClassName="active">
                          vanilla protein
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/chocolate-protein/" activeClassName="active">
                          chocolate protein
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/berry-green-protein/" activeClassName="active">
                          berry green protein
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/rock-and-restore/" activeClassName="active">
                          rock and restore aminos
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/full-body-collagen/" activeClassName="active">
                          full body collagen
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/serenicalm/" activeClassName="active">
                          serenicalm
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/products/whole-sleep/" activeClassName="active">
                          whole sleep
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
              {shipping && (
                <li className="menu-item menu-item--has-dropdown">
                  <div
                    aria-label="Open Products."
                    role="button"
                    tabIndex={-1}
                    onClick={handleMenuDropdown}
                    onKeyDown={handleMenuDropdown}
                  >
                    <Link
                      to="/category/products/"
                      className="menu-item__btn"
                      onClick={closeSidebar}
                      activeClassName="active"
                    >
                      products
                    </Link>
                  </div>
                  <div className="menu-dropdown-toggeable">
                    <ul className="menu-dropdown">
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/category/apparel/" activeClassName="active">
                          Apparel
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link onClick={closeSidebar} to="/category/merchandise/" activeClassName="active">
                          Merchandise
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
              <li className="menu-item menu-item--has-dropdown">
                <button className="menu-item__btn" onClick={handleMenuDropdown}>
                  about
                </button>
                <div className="menu-dropdown-toggeable">
                  <ul className="menu-dropdown">
                    <li className="menu-item">
                      <a onClick={closeSidebar} href="https://thebettyrocker.com/about/">
                        the betty rocker
                      </a>
                    </li>
                    <li className="menu-item">
                      <a onClick={closeSidebar} href="https://thebettyrocker.com/about-whole-betty/">
                        whole betty
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="menu-item menu-item--has-dropdown">
                <button className="menu-item__btn" onClick={handleMenuDropdown}>
                  reviews
                </button>
                <div className="menu-dropdown-toggeable">
                  <ul className="menu-dropdown">
                    <li className="menu-item">
                      <a onClick={closeSidebar} href="https://thebettyrocker.com/success-stories/">
                        betty rocker programs
                      </a>
                    </li>
                    <li className="menu-item">
                      <a onClick={closeSidebar} href="https://thebettyrocker.com/supplement-reviews/">
                        whole betty supplements
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="menu-item d-block d-lg-none">
                <a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/blog/">
                  blog
                </a>
              </li>
              <li className="menu-item d-block d-lg-none">
                <a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/faq/">
                  faq
                </a>
              </li>
              <li className="menu-item d-block d-lg-none">
                <a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/contact/">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <div className="header__buttons">
            <a
              href="https://checkout.thebettyrocker.com/account/login/"
              onClick={closeSidebar}
              className="header__btn"
              aria-label="Go to My Account."
            >
              <i className="fa-solid fa-user" />
            </a>
            <Link to="/cart/" className="header__btn header__btn--lg header__btn--pink">
              <i className="fa-regular fa-basket-shopping" /> <span>{cart ? cart.length : 0}</span>
            </Link>
          </div>
        </div>
      </header>
    </>
  )
}
export default Header
